import React, { useState, useEffect } from "react";
import $ from "jquery";
import db from "./firebaseConfig";
import Carousel from "react-bootstrap/Carousel";
import { Modal } from "react-bootstrap";
import Button from "@mui/material/Button";
import successpopup from "./images/sucesspopup2.svg";

function ReviewSubmit(props) {
  const [startReviewCount, setStarReviewCount] = useState("");
  const [textReview, setTextReview] = useState("");
  const [oneStarCount, setOneStarCount] = useState(0);
  const [twoStarCount, setTwoStarCount] = useState(0);
  const [threeStarCount, setThreeStarCount] = useState(0);
  const [fourStarCount, setFourStarCount] = useState(0);
  const [fiveStarCount, setFiveStarCount] = useState(0);
  const [starValue, setStarValue] = useState("");
  const [totalReview, setTotalReviewCount] = useState(0);
  const [feedbackData, setFeedbackData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [reviewerName, setReviewerName] = useState("");
  const [reviewerEmail, setReviewerEmail] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    var count1 = 0;
    var count2 = 0;
    var count3 = 0;
    var count4 = 0;
    var count5 = 0;

    // console.log("props", props.keyword);
    // var docRef = db.collection("StarReview").doc(props.keyword);

    // docRef
    //   .get()
    //   .then((doc) => {
    //     if (doc.exists) {
    //       var data = doc.data();
    //       console.log(data);
    //       count1 = data.onestar;
    //       count2 = data.twostar;
    //       count3 = data.threestar;
    //       count4 = data.fourstar;
    //       count5 = data.fivestar;
    //     } else {
    //       // doc.data() will be undefined in this case
    //       console.log("No such document!");
    //     }
    //     setOneStarCount(data.onestar);
    //     setTwoStarCount(data.twostar);
    //     setThreeStarCount(data.threestar);
    //     setFourStarCount(data.fourstar);
    //     setFiveStarCount(data.fivestar);
    //     setTotalReviewCount(count1 + count2 + count3 + count4 + count5);
    //   })
    //   .catch((error) => {
    //     console.log("Error getting document:", error);
    //   });
    var text = [];
    db.collection("TextReview")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          var data = doc.data();
          console.log("log", data)
          if (data.keyword === props.keyword && data.accept === "true") {
            text.push(data);
            console.log("log2", data)
          }
        });
        setFeedbackData(text);
        setTotalReviewCount(text.length)
      });
  }
  $(document).ready(function () {
    $("#st1").click(function () {
      $(".fa-star").css("color", "#CBCBCD");
      $("#st1").css("color", "#FFB800");
      setStarValue("1");
      let countIncrease = oneStarCount + 1;
      setStarReviewCount(countIncrease);
    });
    $("#st2").click(function () {
      $(".fa-star").css("color", "#CBCBCD");
      $("#st1, #st2").css("color", "#FFB800");
      setStarValue("2");
      let countIncrease = twoStarCount + 1;
      setStarReviewCount(countIncrease);
    });
    $("#st3").click(function () {
      $(".fa-star").css("color", "#CBCBCD");
      $("#st1, #st2, #st3").css("color", "#FFB800");
      setStarValue("3");
      let countIncrease = threeStarCount + 1;
      setStarReviewCount(countIncrease);
    });
    $("#st4").click(function () {
      $(".fa-star").css("color", "#CBCBCD");
      $("#st1, #st2, #st3, #st4").css("color", "#FFB800");
      setStarValue("4");
      let countIncrease = fourStarCount + 1;
      setStarReviewCount(countIncrease);
    });
    $("#st5").click(function () {
      $(".fa-star").css("color", "#CBCBCD");
      $("#st1, #st2, #st3, #st4, #st5").css("color", "#FFB800");
      setStarValue("5");
      let countIncrease = fiveStarCount + 1;
      setStarReviewCount(countIncrease);
    });
  });

  function submitReview() {
    // Add a new document in collection "cities"
    // if (startReviewCount !== "") {
    //   if (starValue === "1star") {
    //     db.collection("StarReview")
    //       .doc(props.keyword)
    //       .set({
    //         onestar: parseInt(startReviewCount),
    //         twostar: twoStarCount,
    //         threestar: threeStarCount,
    //         fourstar: fourStarCount,
    //         fivestar: fiveStarCount,
    //       })
    //       .then(() => {
    //         console.log("Document successfully written!");
    //         window.location.reload();
    //       })
    //       .catch((error) => {
    //         console.error("Error writing document: ", error);
    //       });
    //   } else if (starValue === "2star") {
    //     db.collection("StarReview")
    //       .doc(props.keyword)
    //       .set({
    //         twostar: parseInt(startReviewCount),
    //         onestar: oneStarCount,
    //         threestar: threeStarCount,
    //         fourstar: fourStarCount,
    //         fivestar: fiveStarCount,
    //       })
    //       .then(() => {
    //         console.log("Document successfully written!");
    //         window.location.reload();
    //       })
    //       .catch((error) => {
    //         console.error("Error writing document: ", error);
    //       });
    //   } else if (starValue === "3star") {
    //     db.collection("StarReview")
    //       .doc(props.keyword)
    //       .set({
    //         threestar: parseInt(startReviewCount),
    //         twostar: twoStarCount,
    //         onestar: oneStarCount,
    //         fourstar: fourStarCount,
    //         fivestar: fiveStarCount,
    //       })
    //       .then(() => {
    //         console.log("Document successfully written!");
    //         window.location.reload();
    //       })
    //       .catch((error) => {
    //         console.error("Error writing document: ", error);
    //       });
    //   } else if (starValue === "4star") {
    //     db.collection("StarReview")
    //       .doc(props.keyword)
    //       .set({
    //         fourstar: parseInt(startReviewCount),
    //         twostar: twoStarCount,
    //         threestar: threeStarCount,
    //         onestar: oneStarCount,
    //         fivestar: fiveStarCount,
    //       })
    //       .then(() => {
    //         console.log("Document successfully written!");
    //         window.location.reload();
    //       })
    //       .catch((error) => {
    //         console.error("Error writing document: ", error);
    //       });
    //   } else if (starValue === "5star") {
    //     db.collection("StarReview")
    //       .doc(props.keyword)
    //       .set({
    //         fivestar: parseInt(startReviewCount),
    //         twostar: twoStarCount,
    //         threestar: threeStarCount,
    //         fourstar: fourStarCount,
    //         onestar: oneStarCount,
    //       })
    //       .then(() => {
    //         console.log("Document successfully written!");
    //         window.location.reload();
    //       })
    //       .catch((error) => {
    //         console.error("Error writing document: ", error);
    //       });
    //   }
    // }

    if (
      textReview !== "" &&
      startReviewCount !== "" &&
      reviewerEmail !== "" &&
      reviewerName !== ""
    ) {
      const d = new Date();
      let year = d.getFullYear();
      let day = d.getDate();
      const month = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const mon = new Date();
      let name = month[mon.getMonth()];
      db.collection("TextReview")
        .doc()
        .set({
          accept: "false",
          keyword: props.keyword,
          review: textReview,
          year: year,
          date: day,
          month: name,
          timestamp: d,
          starValue: starValue,
          starCount: startReviewCount,
          name: reviewerName,
          email: reviewerEmail,
        })
        .then(() => {
          document.getElementById("text-review").value = " ";
          console.log("Document successfully written!");
          window.location.reload();
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    } else {
      if (startReviewCount === "") {
        document.getElementById("comment").style.display = "none";
        document.getElementById("rating").style.display = "block";
        document.getElementById("name").style.display = "none";
        document.getElementById("email").style.display = "none";
      }
      else if (textReview === "") {
        document.getElementById("comment").style.display = "block";
        document.getElementById("rating").style.display = "none";
        document.getElementById("name").style.display = "none";
        document.getElementById("email").style.display = "none";
      }
      else if (reviewerName === "") {
        document.getElementById("comment").style.display = "none";
        document.getElementById("rating").style.display = "none";
        document.getElementById("name").style.display = "block";
        document.getElementById("email").style.display = "none";
      }
      else if (reviewerEmail === "") {
        document.getElementById("comment").style.display = "none";
        document.getElementById("rating").style.display = "none";
        document.getElementById("name").style.display = "none";
        document.getElementById("email").style.display = "block";
      }
      setModalShow(true);
    }
  }

  return (
    <div>
      <section className="pt-40 hr-border-bottom">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h4 className="card-title mt-3">
              <span style={{ color: "#1f8aca" }}>Ratings </span>
              <span style={{ color: "#e9341f" }}>and Reviews</span>
            </h4>
            <h6 className="mt-4">Ratings</h6>
            <div className="mt-3">
              <span
                id="st1"
                class="fa fa-star review-star checked"
                style={{ paddingLeft: "5px" }}
              ></span>
              <span
                id="st2"
                class="fa fa-star review-star checked"
                style={{ paddingLeft: "5px" }}
              ></span>
              <span
                id="st3"
                class="fa fa-star review-star checked"
                style={{ paddingLeft: "5px" }}
              ></span>
              <span
                id="st4"
                class="fa fa-star review-star"
                style={{ paddingLeft: "5px" }}
              ></span>
              <span
                id="st5"
                class="fa fa-star review-star"
                style={{ paddingLeft: "5px" }}
              ></span>
              <span className="rating-count">
                ({totalReview} Reviews)
              </span>
            </div>
            <p id="rating" style={{ display: "none" }} className="text-red m-0 mt-2">
              Please give a rating
            </p>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-12">
            <h6>Comments</h6>
            <textarea
              style={{ width: "100%" }}
              className="card-text rating-textarea"
              id="text-review"
              onChange={(e) => setTextReview(e.target.value)}
            ></textarea>
            <p
              id="comment"
              style={{ display: "none" }}
              className="text-red m-0 mt-2"
            >
              Please give a comment
            </p>
          </div>

          <div className="row">
            <div className="col-6">
              <label>Name</label>
              <input
                type="text"
                className="form-control review-input-css"
                onChange={(e) => setReviewerName(e.target.value)}
              />
            </div>
            <div className="col-6">
              <label>Email</label>
              <input
                type="email"
                className="form-control review-input-css"
                onChange={(e) => setReviewerEmail(e.target.value)}
              />
            </div>
            <div className="col-6 mt-2">
              <p
                id="name"
                style={{ display: "none" }}
                className="text-red m-0"
              >
                Please Enter a name{" "}
              </p>
            </div>
            <div className="col-6 mt-2">
              <p
                id="email"
                style={{ display: "none" }}
                className="text-red m-0"
              >
                Please Enter a email
              </p>
            </div>
            <a
              className="btn-review btn btn-primary mt-4 mb-5"
              onClick={() => submitReview()}
            >
              Submit
            </a>
          </div>


        </div>
      </div>
      </section>

      {feedbackData.length > 0 ? (
      <section className="pt-40 mb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h4 className="card-title mt-3">
              <span style={{ color: "#1f8aca" }}>Reviews on </span>
              <span style={{ color: "#e9341f" }}>{props.name}</span>
            </h4>
              <div className="mt-3 review-content">
              {feedbackData.map((value) => (
                  <div class="review-block">
                    <div class="row">
                      <div className="col-md-12">
                          <div className="d-flex">
                            <div className="rating-box">
                              <span style={{ color: "white", fontSize: "13px" }}>{value.starValue}</span>
                              <span
                                id="st1"
                                class="fa fa-star checked"
                                style={{ paddingLeft: "7px", fontSize: "12px" }}
                              ></span>
                            </div>
                            <div class="form-check form-check-inline">
                              <span class="form-check-label" style={{ paddingLeft: 0, fontSize: "18px" }} for="inlineCheckbox1">
                                <b>{value.name}</b>
                              </span>
                            </div>
                            <div class="form-check form-check-inline p-0">
                              <span class="form-check-label" style={{ color : "#A1A1A1", fontSize: "18px" }} for="inlineCheckbox2">
                                {`<${value.email}>`}
                              </span>
                            </div>
                          </div>
                      </div>
                      <div class="review-block-description mt-3">
                        {value.review}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

      </section>
      ) : null}
      <>
        <Modal
          show={modalShow}
          onHide={() => false}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body className="text-center p-5">
            <img src={successpopup} style={{ width: "65px", height: "65px" }} />
            <h4 className="text-center mt-4">Alert Review & Rating</h4>
            <p className="text-center">
              Dear Customer, you must fill the all options in Rating and Reviews
            </p>
            <Button
              onClick={() => setModalShow(false)}
              className="btn-popup-sucess mt-3"
            >
              Continue
            </Button>
          </Modal.Body>
        </Modal>
      </>
    </div>
  );
}

export default ReviewSubmit;
