// import section start
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./css/footer.css";
import bottomwhatsapp from "../images/bottomwhatsapp.svg";
import bottommail from "../images/bottommail.svg";
import bottomcall from "../images/bottomcall.svg";
import mailfooter from "../images/mailfooter.svg";
import phonecallfooter from "../images/phonecallfooter.svg";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
import { FacebookIcon, TwitterIcon, LinkedinIcon } from "react-share";
// import section End

// Main function start
const Footer = () => {
  const navigate = useNavigate();
  const [href, setHref] = useState("");
  const [currentYear, setCurrentYear] = useState("");

  useEffect(() => {
    setHref(window.location.href);
    const d = new Date();
    let year = d.getFullYear();
    setCurrentYear(year);
  }, []);

  function navigation_app(value) {
    var trim_data_lower_case = value.toLowerCase();
    navigate(`/city/${trim_data_lower_case}`);
    // window.location.reload();
  }

  function static_page_click_footer(pathname) {
    navigate("/" + pathname);
    window.location.reload();
  }
  function categoryClick(paths) {
    navigate("/" + paths);
    window.location.reload();
  }
  function classcomponent() {
    navigate("/housetype/villa-chennai");
    //  window.location.reload();
  }
  return (
    <footer className="mainfooter" role="contentinfo">
      <div className="container">
        <div className="row pt-60 pb-60">
          <div className="col-md-3 responsive-mb-15">
            <h5 className="text-white">About us</h5>
            <div className="mt-4">
              <p className="text-white-light m-0">
                <a
                  onClick={() => static_page_click_footer("about-us")}
                  className="text-white-light m-0"
                >
                  About Us
                </a>
              </p>
              <p className="text-white-light m-0">
                <a
                  onClick={() => static_page_click_footer("privacy-policy")}
                  className="text-white-light m-0"
                >
                  Privacy Policy
                </a>
              </p>
              <p className="text-white-light m-0">
                <a
                  onClick={() => static_page_click_footer("disclaimer")}
                  className="text-white-light m-0"
                >
                  Disclaimer
                </a>
              </p>
            </div>
          </div>
          <div className="col-md-3 responsive-mb-15">
            <h5 className="text-white">Quick menu</h5>
            <div className="mt-4">
              <p className="text-white-light m-0">
                {/* <Link to='/apartment'> */}
                <a
                  onClick={() => categoryClick("apartment")}
                  className="text-white-light m-0 pointer"
                >
                  Apartment
                </a>
                {/* </Link> */}
              </p>
              <p className="text-white-light m-0">
                <a
                  onClick={() => categoryClick("plot")}
                  className="text-white-light m-0 pointer"
                >
                  Plot
                </a>
              </p>
              <p className="text-white-light m-0">
                <a
                  onClick={() => categoryClick("villa")}
                  className="text-white-light m-0 pointer"
                >
                  Villa
                </a>
              </p>
            </div>
          </div>
          <div className="col-md-3 responsive-mb-15">
            <h5 className="text-white">Quick menu</h5>
            <div className="mt-4">
              <p className="text-white-light m-0">
                <a
                  onClick={() => navigation_app("Bangalore")}
                  className="text-white-light m-0 pointer"
                >
                  Bangalore
                </a>
              </p>
              <p className="text-white-light m-0">
                <a
                  onClick={() => navigation_app("Chennai")}
                  className="text-white-light m-0 pointer"
                >
                  Chennai
                </a>
              </p>
              <p className="text-white-light m-0">
                <a
                  onClick={() => navigation_app("Coimbatore")}
                  className="text-white-light m-0 pointer"
                >
                  Coimbatore
                </a>
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <h5 className="text-white">Contact us</h5>
            <div className="mt-4 d-flex">
              <div className="ptr-3-10">
                <img
                  alt="mail-footer"
                  src={mailfooter}
                  width="18"
                  height="18"
                />
              </div>
              <p className="text-white-light m-0">cgchomesllp@gmail.com</p>
            </div>
            <div className="mt-4 d-flex">
              <div className="ptr-3-10">
                <img
                  alt="phone-call"
                  src={phonecallfooter}
                  width="18"
                  height="18"
                />
              </div>
              <div>
                <a href="tel:099406 14444" target="_blank">
                  <p className="text-white-light m-0">099406 14444</p>
                </a>
              </div>
            </div>
            <div className="mt-4 d-flex">
              <div className="ptr-3-10">
                <img
                  alt="phone-call"
                  src={phonecallfooter}
                  width="18"
                  height="18"
                />
              </div>
              <div>
                <a href="tel:+91 96770 51111" target="_blank">
                  <p className="text-white-light m-0">096770 51111</p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <hr></hr>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <p className="text-white-light text-center">
              Copyright CGC, All rights reserved {currentYear}
            </p>
          </div>
        </div>
      </div>
      <div className="bottom-social-icon d-flex">
        <a href="tel:099406 14444" target="_blank">
          <img alt="phone-icon" src={bottomcall} width="45" height="45" />
        </a>
        <a href="mailto:cgchomesllp@gmail.com" target="_blank">
          <img alt="mail-icon" src={bottommail} width="45" height="45" />
        </a>
        <a href="https://api.whatsapp.com/send?phone=919677051111&text=Hi%21+I%27m+interested+in+your+service%21+Please+share+more+details.+Thanks%21">
          <img alt="watsapp-icon" src={bottomwhatsapp} width="45" height="45" />
        </a>
        {/* <FacebookShareButton
        url={href}
      >
        <FacebookIcon width='45' height='45' style={{marginLeft:"5px"}} round /> 
      </FacebookShareButton>
      <TwitterShareButton
        url={href}
      >
        <TwitterIcon style={{marginLeft:"5px"}}  width='45' height='45' round /> 
      </TwitterShareButton>
      <LinkedinShareButton
        url={href}
      >
        <LinkedinIcon style={{marginLeft:"5px"}} width='45' height='45' round /> 
      </LinkedinShareButton> */}
      </div>
    </footer>
  );
};
// Main function end
export default Footer;
