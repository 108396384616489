/* eslint-disable react-hooks/rules-of-hooks */
// Project status base projects shown on this file
// import section start
import * as React from 'react';
import { useNavigate,useLocation } from "react-router-dom";
import db from './firebaseConfig';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { useState,useEffect,useMemo } from 'react';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { CardMedia } from '@mui/material';
import Footer from './Footer';
import locationmarker from '../images/locationmarker.svg';
import "./css/home-styles.css";
import { ReactSVG } from 'react-svg';
import norecord from"../images/no record found.svg";
import loadIcon from "./images/loading_icon.gif"
import Pagination from './Pagination';
import firebase from 'firebase';
import StatusPageFreq from './freqQuestions/statusFreq';
// import section end

var storage = firebase.storage();
// Main function start
function statusPage() {

const [info , setInfo] = useState([]);
const [buildername , setBuilderName] = useState([]);
const [searchoption,setSearchoption] = useState('');
const [noData, setNoData] = useState(false)
const [loadData, setLoadData] = useState(false)

let location = useLocation();
var pathname_split;
var state_get;
var split_base_slash;
var title_tab_= document.title;
// program to convert first letter of a string to uppercase
function capitalizeFirstLetter(str) {

  // converting first letter to uppercase
  const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

  return capitalized;
}

state_get = location.pathname
pathname_split = state_get.split("/")[2]
var categaory_name = state_get.split("/")[1]
var split_base = pathname_split.replace(/-/g, ' ')
split_base_slash = capitalizeFirstLetter(split_base)
console.log(split_base_slash)

useEffect(() => {
  window.scrollTo(0, 0)
  setLoadData(true)
  setNoData(false)
  seo_content()
// Fetchdata()

}, [location,title_tab_])

  // Fetch the seo content for stroed title is display on browser tab
const seo_content = ()=>{
  if (split_base_slash ==="New launch"){
    split_base_slash ="New Launch"
  }else if (split_base_slash ==="Ready to built"){
    split_base_slash = "Ready to Built"
  }
  db.collection("Seo_section")
  .doc(categaory_name+"-"+split_base_slash)
  .get()
  .then((querySnapshot) => {
    // Loop get the data fetching here
      var data = querySnapshot.data();
      console.log(data)
      console.log(data.seo.seo_title) 
      var title_tab_get = data.seo.seo_title 
      // document.title = title_tab_get
      if(title_tab_get === document.title){
        Fetchdata();
      }
      else{
        window.location.reload();
       }
  });
}
      // end
// data fetch function start
const Fetchdata = ()=>{
  if (split_base_slash ==="New launch"){
    split_base_slash ="New Launch"
  }else if (split_base_slash ==="Ready to built"){
    split_base_slash = "Ready to Built"
  }
  if (split_base_slash !==""){
    db.collection("Project_Details")
    .where("project_status", "==", split_base_slash)
    .get()
    .then((querySnapshot) => {
      // Loop get the data fetching here
      let vilaSearchresults = []
      querySnapshot.forEach((element) => {
        var data = element.data();
        data.docId = element.id;    
        vilaSearchresults.push(data)
        var builder_name_assign = data.builder_details.builder_name
    setBuilderName(arr => [...arr, builder_name_assign])
      });
      setLoadData(false)
      setInfo(vilaSearchresults)
      if(info.length === 0){
        setNoData(true)
      }
    });
  }


}
// data fetch function end

// filter section start
const [city, setCity] = React.useState('');
const [area, setArea] = React.useState('');
const [name, setName] = React.useState('');
const [type, setType] = React.useState('');

const handleChangeCity = (event) => {
  setCity(event.target.value);
};
const handleChangeName = (event) => {
  setName(event.target.value);

};
const handleChangeArea = (event) => {
  setArea(event.target.value);
};
const handleChangeType = (event) => {
  setType(event.target.value);
};

// filter function start
function filter_search(){

  setSearchoption('');
  setNoData(true)
  if (area === "" && city === "" && type==="") {
    db.collection("Project_Details")
    .where("builder_details.builder_name","==",name)
    .get()
    .then((querySnapshot) => {
        
        // Loop get the data fetching here
        let filterVilaLists =[]
        querySnapshot.forEach(element => {
          var data = element.data();
          data.docId = element.id;
          filterVilaLists.push(data)
   
        });   
        setInfo(filterVilaLists)
    })  
  } 
  else if (name === "" && city === "" && type==="") {
    db.collection("Project_Details")
    .where("builder_area","==",area)
    .get()
    .then((querySnapshot) => {
        
        // Loop get the data fetching here
        let filterVilaLists = []
        querySnapshot.forEach(element => {
          var data = element.data();   
          data.docId = element.id;
          filterVilaLists.push(data)
        
        });   
        setInfo(filterVilaLists)
    })  
  }
  else if (name === "" && area === "" && type ==="") {
    db.collection("Project_Details")
    .where("city","==",city)
    .get()
    .then((querySnapshot) => {
        
      let filterVilaLists = []
        // Loop get the data fetching here
        querySnapshot.forEach(element => {
          var data = element.data();
          data.docId = element.id;
          filterVilaLists.push(data)

        });   
        setInfo(filterVilaLists)
    })  
  }  
  else if (city === "" && area === "" && name ==="") {
    db.collection("Project_Details")
    .where("house_type","==",type)
    .get()
    .then((querySnapshot) => {
        
      let filterVilaLists = []
        // Loop get the data fetching here
        querySnapshot.forEach(element => {
          var data = element.data();       
        
          data.docId = element.id;
          filterVilaLists.push(data)

        });   
        setInfo(filterVilaLists)
    })  
  }  

  else if (name === "" && city === "" ) {
    db.collection("Project_Details")
    .where("builder_area","==",area)
    .where("house_type","==",type)
    .get()
    .then((querySnapshot) => {
        
        // Loop get the data fetching here
        let filterVilaLists = []
        querySnapshot.forEach(element => {
          var data = element.data();
                
          data.docId = element.id;
          filterVilaLists.push(data)
        
        });   
        setInfo(filterVilaLists)
    })  
  }
  else if (name === "" && area === "" ) {
    db.collection("Project_Details")
    .where("city","==",city)
    .where("house_type","==",type)
    .get()
    .then((querySnapshot) => {
        
      let filterVilaLists = []
        // Loop get the data fetching here
        querySnapshot.forEach(element => {
          var data = element.data();
              
          data.docId = element.id;
          filterVilaLists.push(data)

        });   
        setInfo(filterVilaLists)
    })  
  }  
  else if (name === "" && type === "" ) {
    db.collection("Project_Details")
    .where("builder_area","==",area)
    .where("city","==",city)
    .get()
    .then((querySnapshot) => {
        
      let filterVilaLists = []
        // Loop get the data fetching here
        querySnapshot.forEach(element => {
          var data = element.data();
             
          data.docId = element.id;
          filterVilaLists.push(data)

        });   
        setInfo(filterVilaLists)
    })  
  }  

  else if (area === "" && city === "" ) {
    db.collection("Project_Details")
    .where("builder_details.builder_name","==",name)
    .where("house_type","==",type)
    .get()
    .then((querySnapshot) => {
        
        // Loop get the data fetching here
        let filterVilaLists = []
        querySnapshot.forEach(element => {
          var data = element.data();    
          data.docId = element.id;
          filterVilaLists.push(data)
        
        });   
        setInfo(filterVilaLists)
    })  
  }
  else if (area === "" && type === "" ) {
    db.collection("Project_Details")
    .where("city","==",city)
    .where("builder_details.builder_name","==",name)
    .get()
    .then((querySnapshot) => {
        
      let filterVilaLists = []
        // Loop get the data fetching here
        querySnapshot.forEach(element => {
          var data = element.data();  
          data.docId = element.id;
          filterVilaLists.push(data)

        });   
        setInfo(filterVilaLists)
    })  
  }  
  else if (city === "" && type === "" ) {
    db.collection("Project_Details")
    .where("builder_area","==",area)
    .where("builder_details.builder_name","==",name)
    .get()
    .then((querySnapshot) => {
        
      let filterVilaLists = []
        // Loop get the data fetching here
        querySnapshot.forEach(element => {
          var data = element.data();   
          data.docId = element.id;
          filterVilaLists.push(data)

        });   
        setInfo(filterVilaLists)
    })  
  }  
 else if (city === ""){ 
  db.collection("Project_Details")
    .where("builder_details.builder_name","==",name)
    .where("builder_area","==",area)
    .where("house_type","==",type)
    .get()
    .then((querySnapshot) => {
        
      let filterVilaLists = []
        // Loop get the data fetching here
        querySnapshot.forEach(element => {
          var data = element.data();
          data.docId = element.id;
          filterVilaLists.push(data)
     
        });   
        setInfo(filterVilaLists)
    })  
  } 

  else if (type === ""){
    db.collection("Project_Details")
    .where("city","==",city)
    .where("builder_area","==",area)
    .where("builder_details.builder_name","==",name)
    .get()
    .then((querySnapshot) => {
        let filterVilaLists = []
        // Loop get the data fetching here
        querySnapshot.forEach(element => {
          var data = element.data(); 
          data.docId = element.id;
          filterVilaLists.push(data)
    
        }); 
        setInfo(filterVilaLists)  
    })  
    }     
  else if (area === ""){
    db.collection("Project_Details")
      .where("city","==",city)
      .where("builder_details.builder_name","==",name)
      .where("house_type","==",type)
      // .where("builder_area","==",age2)
      .get()
      .then((querySnapshot) => {
          
        let filterVilaLists =[]
          // Loop get the data fetching here
          querySnapshot.forEach(element => {
            var data = element.data();
            data.docId = element.id;
            filterVilaLists.push(data)
       
          });   
          setInfo(filterVilaLists)
      })  
    } 
    else if (name === ""){
      db.collection("Project_Details")
      .where("city","==",city)
      .where("builder_area","==",area)
      .where("house_type","==", type)
      .get()
      .then((querySnapshot) => {
          let filterVilaLists = []
          // Loop get the data fetching here
          querySnapshot.forEach(element => {
            var data = element.data();     
            data.docId = element.id;
            filterVilaLists.push(data)
      
          }); 
          setInfo(filterVilaLists)  
      })  
      }     

  else {
    db.collection("Project_Details")
      .where("city", "==", city)
      .where("builder_details.builder_name", "==", name)
      .where("builder_area", "==", area)
      .where("house_type","==",type)
      .get()
      .then((querySnapshot) => {
        let filterVilaLists =[]
        querySnapshot.forEach((element) => {
          var data = element.data();
          data.docId = element.id;
          filterVilaLists.push(data)
        });
        setInfo(filterVilaLists)
      });
  } 
}
// filter function end

const groupBy = (key) => (array) =>
array.reduce((objectsByKeyValue, obj) => {
  const value = obj[key];
  objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
  return objectsByKeyValue;
}, {});

const groupBycity = groupBy('city');
var split_group_by_builder_city = groupBycity(info)

const groupByarea = groupBy('builder_area');
var split_group_by_builder_area = groupByarea(info)

groupBy('builder_area');
groupBy(buildername)
var uniquebuildername = [...new Set(buildername)];

// Pagination section start
    let PageSize = 12;
    const [currentPage, setCurrentPage] = useState(1);
    const [sliceArray, setSliceArray] = useState([]);
  
    const splitDataForPagination = () =>{
      const firstPageIndex = (currentPage - 1) * PageSize;
      const lastPageIndex = firstPageIndex + PageSize;
      setSliceArray(info.slice(firstPageIndex, lastPageIndex));

    }
   useMemo(() => {
      splitDataForPagination();
  
    }, [currentPage, info]);

  // pagination section end
	return (
    <div>
      <div
        className="background-img"
        style={{ height: "170px", width: "100%" }}
      >
        <div
          className="d-flex flex-column w-100 align-items-center justify-content-center"
          style={{ height: "170px", width: "100%" }}
        >
          <h1 className="text-white text-center">{split_base_slash}</h1>
        </div>
      </div>

      <div className="container-fluid pt-5 pb-70">
        <div className="container">
          <div className="row filter-ui justify-content-center">
            <div className="col-md-2">
              <p className="mb-2">Select Location</p>
              <select
                className="opt-true"
                onChange={handleChangeCity}
              >
                <option value="">Select Location</option>
                {Object.keys(split_group_by_builder_city).map((assign_city) => (
                  <option>{assign_city}</option>
                ))}
              </select>
            </div>
            <div className="col-md-2">
              <p className="mb-2">Select Property type</p>
              <select onChange={handleChangeType}>
                <option value="">Select type</option>
                <option >Apartment</option>
                <option >Plot</option>    
                <option >Vila</option>
              </select>
            </div>
            <div className="col-md-2">
              <p className="mb-2">Select builder</p>
              <select onChange={handleChangeName}>
                <option value="">Select name</option>

                {uniquebuildername.map((builder_name) => (
                  <option>{builder_name}</option>
                ))}
              </select>
            </div>
            <div className="col-md-2">
              <p className="mb-2">Select Zone</p>
              <select onChange={handleChangeArea}>
                <option value="">Select Zone</option>
                {Object.keys(split_group_by_builder_area).map((area_data) => (
                  <option>{area_data}</option>
                ))}
              </select>
            </div>
            <div className="col-md-2">
              <button
                onClick={() => filter_search()}
                type="Search"
                className="search-filter-btn"
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
        
        {info.length !==0 ?
      <div className="container-fluid bg-grey">
        <div className="container pt-40 pb-60 search-card-list">
          <div className="row">
            {/* Pass the info state to frame function */}
            {sliceArray.map((data, querySnapshot) => (
              <DataPass
                builder_project_img={data.project_img}
                builder_status={data.project_status}
                builder_address={data.Address}
                builder_id={data.docId}
                project_name={data.project_name}
                project_name_keyword = {data.project_name_keyword}
                project_city = {data.project_city}
              />
            ))}
          </div>

          <div className="row pt-40">
            <div className="col-md-12">
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={info.length}
                pageSize={PageSize}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          </div>
        </div>
      </div>:null
        }

{info.length === 0 & loadData===true ? (
<div className="container-fluid bg-grey p-0">
<div className="container pt-40 pb-60 search-card-list">
        <div className="row">
                <div className="col-md-12 text-center p-5">
                <img src={loadIcon} width="50px" height="50px"/>
                </div> 
            </div>
          </div>
          <StatusPageFreq />
          <Footer/>
      </div>
) : null}
          {info.length === 0 & noData === true ? (
      <div className="container-fluid bg-grey p-0">
       <div className="container pt-40 pb-60 search-card-list">
        <div className="row">
                <div className="col-md-12 text-center">
                <img src={norecord} className="norecord" />
                  </div>        
            </div>
          </div>
          <Footer/>
      </div>
              ) : null}

      <div>{info.length > 0 ? <StatusPageFreq /> : null}</div>
      <div>{info.length > 0 ? <Footer /> : null}</div>
    </div>
  );
}
// Main function end

// Data function recall 
const DataPass = (
  {
  builder_project_img,
  builder_status,
  builder_address,
  builder_id,
  project_name,
  project_name_keyword,
  project_city
}) => {

  const navigate = useNavigate();

// groupby function create

const goToPosts = (value,p_name) =>{

navigate(
      
  `/projects/${p_name}`

,{state:{id:value.docId}})
window.location.reload();

}
// Fetching images set an alt image name 
var httpsReference_project_logo = storage.refFromURL(builder_project_img);
let alt_image_name = httpsReference_project_logo.name;
    // Fetching images set an alt image name end
  return (
       
	<div className='col-md-4 pt-40 nthclr'>
    
    <Box sx={{ minWidth: 175,backgroundColor:"greenyellow" }}>
      <Card>
      <React.Fragment>
      <CardContent onClick={()=>goToPosts(builder_id,project_name_keyword)} style={{padding: "0",border: "none", width: "100%",}} className="pointer">
      <div className='relative'>
        <div className="image-hover">
          <CardMedia component="img" className="searchFilterCardListImg" 
          image={builder_project_img } alt={alt_image_name}></CardMedia>
        </div>
      <div className="content-sep-top">
        <h6>{project_name}</h6>
        <div className="d-flex pt-1">
           <ReactSVG src={locationmarker} />
          <p style={{marginBottom: "0", marginLeft: "5px",}}>{builder_address},{" "}{project_city}</p>
        </div>
      </div>
      <div className='projStatusbtn-absolute' >
        <Button className="projStatusbtn nthbg">{builder_status}</Button>
      </div>
      </div>
      </CardContent>
      </React.Fragment>
      </Card>
    </Box>
    
  </div>
  )
  
}

export default statusPage;
