import Accordion from 'react-bootstrap/Accordion';
import { useEffect, useState } from "react";
import db from "../firebaseConfig";
import {useLocation } from "react-router-dom";
import "./frequentScroller.css";

function ProjectsPageFreq(){
  const [array, setArray]= useState([])
  useEffect(() => {
    faqFetch()
  
  }, [])
  
  var location = useLocation();

  const faqFetch = () => {
    var split_base_slash;
    var key_word;
    key_word = location.pathname;
    split_base_slash = key_word.split("/")[2];
    
    db.collection("Project_Details")
      .where("project_name_keyword", "==", split_base_slash)
      .get()
      .then((querySnapshot) => {
        // Loop through the data and store
        querySnapshot.forEach((element) => {
          var data = element.data();
          console.log("data",data);
          setArray(data.Faq)
        });
      });
  };
    return( 
      <div className="container-fluid home-faq-bg">
        {array !== undefined && array.length > 0 ? (
      <div className="container pt-70 pb-60">
        <div className="row">
          <div className="col-md-12">
            <h1 className="text-center mb-5">Frequently Asked Questions</h1>
            <Accordion className="scroller" eventKey="0">
            {array.map((value, index) => (

              <Accordion.Item eventKey={index}>
                <Accordion.Header>{value.questions}</Accordion.Header>
                <Accordion.Body>
                  {value.answers}
                </Accordion.Body>
              </Accordion.Item>
            ))}
            </Accordion>
          </div>
        </div>
      </div>
        ):null}
    </div>   
    )
}

export default ProjectsPageFreq;