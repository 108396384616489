import {useNavigate,Outlet, Link } from "react-router-dom";
import jQuery from 'jquery';
import db from './firebaseConfig';
import { useState,useEffect } from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import logo from "../images/logo.png";
import './css/navbar.scss';
import './css/update-style.css';

(function($) { // Begin jQuery
  $(function() { // DOM ready
    // If a link has a dropdown, add sub menu toggle.
    $('nav ul li a:not(:only-child)').click(function(e) {
      $(this).siblings('.nav-dropdown').toggle();
      // Close one dropdown when selecting another
      $('.nav-dropdown').not($(this).siblings()).hide();
      e.stopPropagation();
    });
    // Clicking away from dropdown will remove the dropdown className
    $('html').click(function() {
      $('.nav-dropdown').hide();
    });
    // Toggle open and close nav styles on click
    $('#nav-toggle').click(function() {
      $('nav ul').slideToggle();
    });
    // Hamburger to X toggle
    $('#nav-toggle').on('click', function() {
      this.classList.toggle('active');
    });
  }); // end DOM ready
})(jQuery); // end jQuery

// Close the dropdown if the user clicks outside of it
window.onclick = function(event) {
  if (!event.target.matches('.dropbtn')) {
    var dropdowns = document.getElementsByClassName("dropdown-content");
    var i;
    for (i = 0; i < dropdowns.length; i++) {
      var openDropdown = dropdowns[i];
      if (openDropdown.classList.contains('show')) {
        openDropdown.classList.remove('show');
      }
    }
  }
}

const Layout = () => {

  const [cityinfo , setCityInfo] = useState([]);

  useEffect(() => {
    Fetchcitydata()
    }, [])
  // Close the dropdown if the user clicks outside of it
  const Fetchcitydata = ()=>{
    
		db.collection("Project_Details").get().then((querySnapshot) => {
			
			// Loop get the data fetching here
			querySnapshot.forEach(element => {
				var data = element.data();  
        data.docId = element.id;
				setCityInfo(arr => [...arr , data]);
			});   
  })   
}
 return(
  <Navbar cities={cityinfo}/>

 );

}

const Navbar = ({cities }) => {
  const navigate_appartment = useNavigate();

  function goToStatus(nav_city){

      let data1=nav_city.trim().split(' ')
      var trim_data =data1.join('-')
      var trim_data_lower_case = trim_data.toLowerCase()
      navigate_appartment(`/status/${trim_data_lower_case}`
      ,{state:{id:trim_data,seo_section:nav_city}})
      window.location.reload();
      }

const groupBy_ = key => array =>
array.reduce((objectsByKeyValue,obj)=>{
  const value = obj[key];
  objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
  return objectsByKeyValue;
},{});

const groupBycity_ = groupBy_('city');
var split_group_by_city = groupBycity_(cities)

const groupByStatus_ = groupBy_('project_status');
var split_group_by_status = groupByStatus_(cities)

function apartment_click(nav_city,category_name){
  var trim_data_lower_case = nav_city.toLowerCase()
  navigate_appartment(
      
    `/${category_name+'-'+trim_data_lower_case}`

  ,{state:{id:nav_city}})
  window.location.reload();
}

// function apartment_click_villa(nav_city,category_name){
//   var trim_data_lower_case = nav_city.toLowerCase()
//   navigate_appartment(
      
//     `/${'housetype/villa-'+trim_data_lower_case}`
// )
//   window.location.reload();
// }

function Category_Click(pass_data){
  let category_ = pass_data
  let data1=pass_data.trim().split(' ')
  var trim_data =data1.join('-')
  var trim_data_lower_case = trim_data.toLowerCase()
  navigate_appartment(`/property-category/${trim_data_lower_case}`
  ,{state:{category:pass_data}})
  window.location.reload();
 
}

function static_page_click(){
  
  navigate_appartment(`/contact`)
  window.location.reload();
}
function homePage(){
  navigate_appartment("/")
   window.location.reload();
}
  return (
    <div>
      <section className="navigation" id="default-nav">
        <div className="nav-container">
          <div className="brand">
              <a onClick={()=>homePage()}>
                <img src={logo}  alt="cgc-logo"/>
              </a>
          </div>
          <nav>
            <div className="nav-mobile">
              <a id="nav-toggle" href="#!">
                <span></span>
              </a>
            </div>
            <ul className="nav-list">
                <li >
                  <a onClick={()=>homePage()} >Home</a>
                </li>
              <li>
                <a>Apartment</a>
                <ul className="nav-dropdown">
                  {Object.keys(split_group_by_city).map((nav_city) => (
                    <li>
                      <a onClick={() => apartment_click(nav_city,'apartment')} id={nav_city}>
                        {nav_city}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
              
              <li>
                <a>Plot</a>
                <ul className="nav-dropdown">
                  {Object.keys(split_group_by_city).map((nav_city) => (
                    <li>
                      <a onClick={() =>apartment_click(nav_city,'plot')} id={nav_city}>
                        {nav_city}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>

              <li>
                <a>Villa</a>
                <ul className="nav-dropdown">
                  {Object.keys(split_group_by_city).map((nav_city) => (
                    <li>
                      <a onClick={() =>apartment_click(nav_city,'villa')} id={nav_city}>
                        {nav_city}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
              <li>
                <a>Property Category</a>
                <ul className="nav-dropdown">
                    <li>
                      <a onClick={()=>Category_Click("Luxury")}>Luxury</a>
                    </li>          
                    <li>
                      <a onClick={()=>Category_Click("Ultra Luxury")}>Ultra Luxury</a>
                    </li>
                    <li>
                      <a onClick={()=>Category_Click("Premium")}>Premium</a>
                    </li>
                    <li>
                      <a onClick={()=>Category_Click("Ultra premium")}>Ultra premium</a>
                    </li>
                    <li>
                      <a onClick={()=>Category_Click("Affordable")}>Affordable</a>
                    </li>
                </ul>
              </li>

              <li>
                <a>Status</a>
                <ul className="nav-dropdown">
                  {Object.keys(split_group_by_status).map((nav_status) => (
                    <li>
                      <a onClick={() => goToStatus(nav_status)} id={nav_status}>
                        {nav_status}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
              {/* <Link to="/contact"> */}
                <li>
                  <a onClick={()=>static_page_click()}>Contact</a>
                </li>
              {/* </Link> */}
            </ul>
          </nav>
        </div>
      </section>

      <Outlet />
    </div>
  );

};

export default Layout;