/* eslint-disable react-hooks/rules-of-hooks */
// import section start
import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import db from "./firebaseConfig";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { useState, useEffect, useMemo } from "react";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { CardMedia } from "@mui/material";
import { ReactSVG } from "react-svg";
import Footer from "./Footer";
import locationmarker from "../images/locationmarker.svg";
import "./css/home-styles.css";
import norecord from "../images/no record found.svg";
import Pagination from "./Pagination";
// import section end

// Main function start
function searchPage() {
  const [info, setInfo] = useState([]);
  const [buildername, setBuilderName] = useState([]);
  const [searchoption, setSearchoption] = useState("");
  const [fullinfo, setFullInfo] = useState([]);

  let location = useLocation();

  var state_id = location.state;
  useEffect(() => {
    Fetchdata();
  }, [state_id]);

  // Fetch the required data using the get() method

  const Fetchdata = () => {
    var state_id = location.state;
    console.log(state_id);
    if (state_id.searchData === "") {
      console.log("search result empty");
      db.collection("Project_Details")
        .where("city", "==", state_id.city)
        .get()
        .then((querySnapshot) => {
          // Loop get the data fetching here
          querySnapshot.forEach((element) => {
            var data = element.data();

            data.docId = element.id;
            setInfo((arr) => [...arr, data]);
            var full_data = element.data();

            full_data.docId = element.id;
            // setFullInfo((arr) => [...arr, full_data]);
  
            var builder_name_assign = full_data.builder_details.builder_name;
            setBuilderName((arr) => [...arr, builder_name_assign]);
          });
        });
    } else if (state_id.city === "") {
      // const strStart = remove_space.charAt(0).toUpperCase() + remove_space.slice(1);
      console.log("search city empty");

      const searchString = state_id.searchData.toLowerCase();

      console.log("search string", searchString);

      db.collection("Project_Details")
        .get()
        .then((querySnapshot) => {
          // Loop get the data fetching here
          querySnapshot.forEach((element) => {
            var data = element.data();

            data.docId = element.id;
            var builder_name = data.builder_details.builder_name;
            var project_name = data.project_name;
            var project_address = data.Address;
            // if(builder_name.search(remove_space) !== -1 || builder_name.search(strStart) !== -1 || builder_name.search(strEnd) !== -1 || project_name.search(remove_space) !== -1 || project_name.search(strStart) !== -1 || project_name.search(strEnd) !== -1
            // || project_address.search(remove_space) !== -1 || project_address.search(strStart) !== -1 || project_address.search(strEnd) !== -1){
            if (
              builder_name.toLowerCase().search(searchString) !== -1 ||
              project_name.toLowerCase().search(searchString) !== -1 ||
              project_address.toLowerCase().search(searchString) !== -1
            ) {
              setInfo((arr) => [...arr, data]);
            }
          });
        });
    } else {
      // const strStart = remove_space.charAt(0).toUpperCase() + remove_space.slice(1);
      const searchString = state_id.searchData.toLowerCase();
      console.log("no empty");

      db.collection("Project_Details")
        .where("city", "==", state_id.city)
        .get()
        .then((querySnapshot) => {
          // Loop get the data fetching here
          querySnapshot.forEach((element) => {
            var data = element.data();

            data.docId = element.id;

            var builder_name = data.builder_details.builder_name;
            var project_name = data.project_name;
            var project_address = data.Address;
            if (
              builder_name.toLowerCase().search(searchString) !== -1 ||
              project_name.toLowerCase().search(searchString) !== -1 ||
              project_address.toLowerCase().search(searchString) !== -1
            ) {
              setInfo((arr) => [...arr, data]);
            }
          });
        });
    }
    db.collection("Project_Details")
      .get()
      .then((querySnapshot) => {
        // Loop get the data fetching here
        querySnapshot.forEach((element) => {
     
          var full_data = element.data();

          full_data.docId = element.id;
          setFullInfo((arr) => [...arr, full_data]);
        });
      });
  };
  const [city, setCity] = React.useState("");
  const [area, setArea] = React.useState("");
  const [name, setName] = React.useState("");

  const handleChangeCity = (event) => {
    setCity(event.target.value);
    if (event.target.value !== "") {
      // Filter builder names based on the selected city
      const filteredBuilders = fullinfo
        .filter((project) => project.city === event.target.value)
        .map((project) => project.builder_details.builder_name);
      
      setBuilderName([...new Set(filteredBuilders)]); // Remove duplicates and set builder names
    } else {
      setBuilderName([]); // Clear builder dropdown when no city is selected
    }
  };
  const handleChangeName = (event) => {
    setName(event.target.value);
  };
  const handleChangeArea = (event) => {
    setArea(event.target.value);
  };
  function filter_search() {
    // filter_hash_content = "";
    setSearchoption("");
    if (area === "" && city === "") {
    
      db.collection("Project_Details")          
        .where("builder_details.builder_name", "==", name)
        .get()
        .then((querySnapshot) => {
          let SearchLists = [];
          // Loop get the data fetching here
          querySnapshot.forEach((element) => {
            var data = element.data();

            data.docId = element.id;
            SearchLists.push(data);
          });
          setInfo(SearchLists);
        }); 
    } else if (name === "" && city === "") {
      db.collection("Project_Details")
        .where("builder_area", "==", area)
        .get()
        .then((querySnapshot) => {
          let SearchLists = [];
          // Loop get the data fetching here
          querySnapshot.forEach((element) => {
            var data = element.data();

            data.docId = element.id;
            SearchLists.push(data);
          });
          setInfo(SearchLists);
        });
    } else if (name === "" && area === "") {
      db.collection("Project_Details")
        .where("city", "==", city)
        .get()
        .then((querySnapshot) => {
          let SearchLists = [];
          // Loop get the data fetching here
          querySnapshot.forEach((element) => {
            var data = element.data();

            data.docId = element.id;
            SearchLists.push(data);
          });
          setInfo(SearchLists);
        });
    } else if (city === "") {
      db.collection("Project_Details")
        .where("builder_details.builder_name", "==", name)
        .where("builder_area", "==", area)

        .get()
        .then((querySnapshot) => {
          let SearchLists = [];
          // Loop get the data fetching here
          querySnapshot.forEach((element) => {
            var data = element.data();

            data.docId = element.id;
            SearchLists.push(data);
          });
          setInfo(SearchLists);
        });
    } else if (area === "") {
      db.collection("Project_Details")
        .where("city", "==", city)
        .where("builder_details.builder_name", "==", name)
        .get()
        .then((querySnapshot) => {
          let SearchLists = [];
          // Loop get the data fetching here
          querySnapshot.forEach((element) => {
            var data = element.data();

            data.docId = element.id;
            SearchLists.push(data);
          });
          setInfo(SearchLists);
        });
    } else if (name === "") {
      db.collection("Project_Details")
        .where("city", "==", city)
        .where("builder_area", "==", area)
        .get()
        .then((querySnapshot) => {
          let SearchLists = [];
          // Loop get the data fetching here
          querySnapshot.forEach((element) => {
            var data = element.data();

            data.docId = element.id;
            SearchLists.push(data);
          });
          setInfo(SearchLists);
        });
    } else {
      db.collection("Project_Details")
        .where("city", "==", city)
        .where("builder_details.builder_name", "==", name)
        // .where("builder_area", "==", area)
        .get()
        .then((querySnapshot) => {
          let SearchLists = [];
          querySnapshot.forEach((element) => {
            var data = element.data();

            data.docId = element.id;
            SearchLists.push(data);
          });
          setInfo(SearchLists);
        });
    }
  }

  useEffect(() => {
    if (city) {
    }
  }, [city]);
  
  function try_select_() {
    setCity(state_id.city);

    if (state_id.searchData !== "") {
      setSearchoption("/" + state_id.searchData);
    }
  }

  const groupBy = (key) => (array) =>
    array.reduce((objectsByKeyValue, obj) => {
      const value = obj[key];
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {});

  const groupBycity = groupBy("city");
  var split_group_by_builder_city = groupBycity(fullinfo);

  const groupByarea = groupBy("builder_area");
  var split_group_by_builder_area = groupByarea(fullinfo);

  const groupBybuildername = groupBy("builder_area");
  var split_group_by_builder_name = groupBy(buildername);
  var uniquebuildername = [...new Set(buildername)];

  // Display the result on the page
  let PageSize = 12;
  const [currentPage, setCurrentPage] = useState(1);
  const [sliceArray, setSliceArray] = useState([]);
  const [sliceArrayBool, setSliceArrayBool] = useState(false);

  const splitDataForPagination = () => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    setSliceArray(info.slice(firstPageIndex, lastPageIndex));
    setSliceArrayBool(true);
  };
  useMemo(() => {
    splitDataForPagination();
  }, [currentPage, info]);

  return (
    <div>
      <div
        className="background-img"
        style={{ height: "170px", width: "100%" }}
      >
        <div
          className="d-flex flex-column w-100 align-items-center justify-content-center"
          style={{ height: "170px", width: "100%" }}
        >
          <h1 className="text-white text-center">
            Search result{searchoption}
          </h1>
        </div>
      </div>

      <div className="container-fluid pt-5 pb-70">
        <div className="container">
          <div className="row filter-ui">
            <div className="col-md-3">
              <p className="mb-2">Select Location</p>
              <select
                className="opt-true"
                value={city}
                onChange={handleChangeCity}
              >
                <option value="">Select Location</option>

                {Object.keys(split_group_by_builder_city).map((assign_city) => (
                  <option>{assign_city}</option>
                ))}
              </select>
            </div>
            <div className="col-md-3">
              <p className="mb-2">Select builder</p>
              <select onChange={handleChangeName}>
                <option value="">Select name</option>

                {uniquebuildername.map((builder_name) => (
                  <option>{builder_name}</option>
                ))}
              </select>
            </div>
            <div className="col-md-3">
              <p className="mb-2">Select Zone</p>
              <select onChange={handleChangeArea}>
                <option value="">Select Zone</option>
                {Object.keys(split_group_by_builder_area).map((area_data) => (
                  <option>{area_data}</option>
                ))}
              </select>
            </div>
            <div className="col-md-3">
              <button
                onClick={() => filter_search()}
                type="Search"
                className="search-filter-btn"
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>

      {info.length !== 0 ? (
        <div className="container-fluid bg-grey">
          <div className="container pt-40 pb-60 search-card-list">
            <div className="row">
              {/* Pass the info state to frame function */}
              {sliceArray.map((data, querySnapshot) => (
                <Datapage
                  builder_project_img={data.project_img}
                  builder_status={data.project_status}
                  builder_address={data.Address}
                  builder_ID={data.docId}
                  project_name={data.project_name}
                  project_name_keyword={data.project_name_keyword}
                  project_city={data.city}
                />
              ))}
            </div>

            <div className="row pt-40">
              <div className="col-md-12">
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={info.length}
                  pageSize={PageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {info.length === 0 ? (
        <div className="container-fluid bg-grey p-0">
          <div className="container pt-40 pb-60 search-card-list">
            <div className="row">
              <div className="col-md-12 text-center">
                <img src={norecord} className="norecord" />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : null}

      <div>{info.length > 0 ? <Footer /> : null}</div>
    </div>
  );
}
// Main function end

// Data function recall start
const Datapage = ({
  builder_project_img,
  builder_status,
  builder_address,
  builder_ID,
  project_name,
  project_name_keyword,
  project_city,
}) => {
  const navigate = useNavigate();
  // groupby function create
  const goToPosts = (value, p_name) => {
    // let data = p_name;
    // let data1 = data.trim().split(" ");
    // var trim_data = data1.join("-");
    // var trim_data_lower_case = trim_data.toLowerCase()
    navigate(
      `/projects/${p_name}`,

      { state: { id: value.docId } }
    );
    window.location.reload();
  };

  return (
    <div className="col-md-4 pt-40 nthclr">
      <Box sx={{ minWidth: 175, backgroundColor: "greenyellow" }}>
        <Card>
          <React.Fragment>
            <CardContent
              onClick={() => goToPosts(builder_ID, project_name_keyword)}
              style={{ padding: "0", border: "none", width: "100%" }}
              className="pointer"
            >
              <div className="relative">
                <div className="image-hover">
                  <CardMedia
                    component="img"
                    className="searchFilterCardListImg"
                    image={builder_project_img}
                  ></CardMedia>
                </div>
                <div className="content-sep-top">
                  <h6>{project_name}</h6>
                  <div className="d-flex pt-1">
                    <ReactSVG src={locationmarker} />
                    <p style={{ marginBottom: "0", marginLeft: "5px" }}>
                      {builder_address}, {project_city}
                    </p>
                  </div>
                </div>
                <div className="projStatusbtn-absolute">
                  <Button className="projStatusbtn nthbg">
                    {builder_status}
                  </Button>
                </div>
              </div>
            </CardContent>
          </React.Fragment>
        </Card>
      </Box>
    </div>
  );
};

// Data function recall end
export default searchPage;
