// Import section start
import React from 'react';
import locationmarker from '../images/locationmarker.svg';
import mail from '../images/mail.svg';
import phonecall from '../images/phonecall.svg';
import './css/footer.css';
import Footer from "./Footer";
// import section End

// Main function Start
function Contact (){

 return (
   <div>
     <div className="background-img" style={{ height: "170px", width: "100%" }}>
       <div
         className="d-flex flex-column w-100 align-items-center justify-content-center"
         style={{ height: "170px", width: "100%" }}
       >
         <h1 className="text-white text-center">Contact us</h1>
       </div>
     </div>
     <div className="container pt-60 pb-60">
       <div className="row">
         <div className="col-md-12 vertic-top">
           <h1 className="mb-4">Reach out with any questions you have</h1>
           <div className="mt-5 d-flex">
             <div className="ptr-3-10 pt-0">
               <img src={locationmarker} width="20" height="20" />
             </div>
             <div>
               <h5>Address</h5>
               <p className="m-0">
                 1st floor, No.31, Vembuli Amman Kovil Street,Near
                 Pazhavanthangal Railway Station, Pazhavanthangal, Chennai –
                 600114
               </p>
             </div>
           </div>
           <div className="mt-4 d-flex">
             <div className="ptr-3-10 pt-0">
               <img src={mail} width="20" height="20" />
             </div>
             <div>
               <h5>Email</h5>
               <p className="m-0">chennaigatedcommunity@gmail.com</p>
             </div>
           </div>
           <div className="mt-4 d-flex">
             <div className="ptr-3-10 pt-0">
               <img src={phonecall} width="20" height="20" />
             </div>
             <div>
               <h5>Call us directly</h5>
               <p className="m-0">+91 99406 14444 / +91 96770 51111</p>
             </div>
           </div>
         </div>
 
       </div>
     </div>
     {<Footer />}
   </div>
 );
}
// Main function End
export default Contact;