import Accordion from 'react-bootstrap/Accordion';
import { useEffect, useState } from "react";
import db from "../firebaseConfig";
import {useLocation } from "react-router-dom";
import "./frequentScroller.css";

function FooterCityPageFreq(){
  const [array, setArray]= useState([])
  useEffect(() => {
    faqFetch()
  
  }, [])
  
  function capitalizeFirstLetter(str) {
    // converting first letter to uppercase
    const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

    return capitalized;
  }
  let location = useLocation();

  var pathname_get = location.pathname;
  var pathname_split = pathname_get.split("/")[2];
  var split_base = pathname_split.replace(/-/g, ' ')
  var pathname_caps_category = capitalizeFirstLetter(split_base);
  console.log(pathname_caps_category)
  function faqFetch(){
    console.log("function run")
    db.collection('Faq_section').doc("city-"+pathname_caps_category).get()
    .then(snapshot => {
      var data = snapshot.data()
      setArray(data.Faq)
    })
  }
    return(
      <div className="container-fluid white-bg">
      <div className="container pt-70 pb-60">
        <div className="row">
          <div className="col-md-12">
            <h1 className="text-center mb-5">Frequently Asked Questions</h1>
            <Accordion className="scroller" eventKey="0">
            {array.map((value, index) => (

              <Accordion.Item eventKey={index}>
                <Accordion.Header>{value.questions}</Accordion.Header>
                <Accordion.Body>
                {value.answers}
                </Accordion.Body>
              </Accordion.Item>
            ))}
            </Accordion>
          </div>
        </div>
      </div>
    </div>
    )
}

export default FooterCityPageFreq;