/* eslint-disable react-hooks/rules-of-hooks */
// This js file is based on Apartment type homes are shown.
// import section start
import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import db from "./firebaseConfig";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { useState, useEffect, useMemo } from "react";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { CardMedia } from "@mui/material";
import Footer from "./Footer";
import { ReactSVG } from "react-svg";
import locationmarker from "../images/locationmarker.svg";
import "./css/home-styles.css";
import norecord from "../images/no record found.svg";
import loadIcon from "./images/loading_icon.gif";
import Pagination from "./Pagination";
import firebase from "firebase";
import CityPageFreq from "./freqQuestions/cityPage";
// import section end
var storage = firebase.storage();
// Main function start
function CategoryWithCityPage() {
  const [info, setInfo] = useState([]);
  const [buildername, setBuilderName] = useState([]);
  const [cityName, setCityName] = useState([]);
  const [builderArea, setBuilderArea] = useState([]);
  const [searchOption, setSearchOption] = useState("");
  const [noData, setNoData] = useState(false);
  const [loadData, setLoadData] = useState(false);

  var location;
  var pathname_split;
  var cityname_get;
  var category_get;
  var pathname_caps;
  var pathname_caps_category;

  // program to convert first letter of a string to uppercase
  function capitalizeFirstLetter(str) {
    // converting first letter to uppercase
    const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

    return capitalized;
  }

  // try {
  location = useLocation();
  // var city_get = location.state.id;
  var pathname_get = location.pathname;
  try {
    pathname_split = pathname_get.split("/")[1];
    category_get = pathname_split.split("-")[0];
    cityname_get = pathname_split.split("-")[1];
    pathname_caps = capitalizeFirstLetter(cityname_get);
    pathname_caps_category = capitalizeFirstLetter(category_get);
    console.log(cityname_get);
    console.log(category_get);
  } catch (error) {
    pathname_split = pathname_get.split("/")[1];
    pathname_caps_category = capitalizeFirstLetter(pathname_split);

  }


  var title_tab_ = document.title;

  useEffect(() => {
    setLoadData(true);
    setNoData(false);
    seo_content();
    // Fetchdata();
  }, []);

  // Fetch the seo content for stroed title is display on browser tab
  const seo_content = async () => {
    db.collection("Seo_section")
      .doc(category_get + "-" + pathname_caps)
      .get()
      .then((querySnapshot) => {
        // Loop get the data fetching here
        var data = querySnapshot.data();
        console.log(data);
        console.log(data.seo.seo_title);
        var title_tab_get = data.seo.seo_title;
        document.title = title_tab_get
       if (title_tab_get === document.title) {
          Fetchdata()
        }
        else{
          window.location.reload();
         }
      });
  };

  // end
  // Data fetching function start

  const Fetchdata = () => {
    try {
      db.collection("Project_Details")
        .where("house_type", "==", pathname_caps_category)
        .where("city", "==", pathname_caps)
        .get()
        .then((querySnapshot) => {
          // Loop get the data fetching here
          let vilaSearchresults = [];
          querySnapshot.forEach((element) => {
            var data = element.data();
            data.docId = element.id;
            vilaSearchresults.push(data);
            var builder_name_assign = data.builder_details.builder_name;
            setBuilderName((arr) => [...arr, builder_name_assign]);
            var city_name_assign = data.city;
            setCityName((arr) => [...arr, city_name_assign]);
            var builder_area_assign = data.builder_area;
            setBuilderArea((arr) => [...arr, builder_area_assign]);
          });
          setInfo(vilaSearchresults);
          if (info.length > 0) {
            setLoadData(false);
          }
        });
    } catch (error) {
      db.collection("Project_Details")
        .where("house_type", "==", pathname_caps_category)
        .get()
        .then((querySnapshot) => {
          // Loop get the data fetching here
          let vilaSearchresults = [];
          querySnapshot.forEach((element) => {
            var data = element.data();
            data.docId = element.id;
            vilaSearchresults.push(data);
            var builder_name_assign = data.builder_details.builder_name;
            setBuilderName((arr) => [...arr, builder_name_assign]);
            var city_name_assign = data.city;
            setCityName((arr) => [...arr, city_name_assign]);
            var builder_area_assign = data.builder_area;
            setBuilderArea((arr) => [...arr, builder_area_assign]);
          });
          setInfo(vilaSearchresults);
        });
    }
  };

  // Data fetching function end

  // Filter section start
  const [city, setCity] = React.useState("");
  const [area, setArea] = React.useState("");
  const [name, setName] = React.useState("");

  const handleChangeCity = (event) => {
    setCity(event.target.value);
  };
  const handleChangeName = (event) => {
    setName(event.target.value);
  };
  const handleChangeArea = (event) => {
    setArea(event.target.value);
  };
  // filter section end

  // Groupby based on city,builder area and builder name
  const groupBy = (key) => (array) =>
    array.reduce((objectsByKeyValue, obj) => {
      const value = obj[key];
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {});

  groupBy(buildername);
  var uniquebuildername = [...new Set(buildername)];
  groupBy(cityName);
  var uniqueCityName = [...new Set(cityName)];
  groupBy(builderArea);
  var uniquebuilderArea = [...new Set(builderArea)];

  // Display the result on the page
  // Pagination section
  let PageSize = 12;
  const [currentPage, setCurrentPage] = useState(1);
  const [sliceArray, setSliceArray] = useState([]);

  const splitDataForPagination = () => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    setSliceArray(info.slice(firstPageIndex, lastPageIndex));
  };
  useMemo(() => {
    splitDataForPagination();
  }, [currentPage, info]);

  // pagination section end

  // filter function start
  function filter_search() {
    setSearchOption("");
    setLoadData(false);
    setNoData(true);
    if (area === "" && city === "") {
      db.collection("Project_Details")
        .where("builder_details.builder_name", "==", name)
        .where("house_type", "==", pathname_caps_category)
        .get()
        .then((querySnapshot) => {
          let filterApartmentLists = [];
          // Loop get the data fetching here
          querySnapshot.forEach((element) => {
            var data = element.data();

            data.docId = element.id;
            filterApartmentLists.push(data);
          });
          setInfo(filterApartmentLists);
        });
    } else if (name === "" && city === "") {
      db.collection("Project_Details")
        .where("builder_area", "==", area)
        .where("house_type", "==", pathname_caps_category)
        .get()
        .then((querySnapshot) => {
          let filterApartmentLists = [];
          // Loop get the data fetching here
          querySnapshot.forEach((element) => {
            var data = element.data();

            data.docId = element.id;
            filterApartmentLists.push(data);
          });
          setInfo(filterApartmentLists);
        });
    } else if (name === "" && area === "") {
      db.collection("Project_Details")
        .where("city", "==", city)
        .where("house_type", "==", pathname_caps_category)
        .get()
        .then((querySnapshot) => {
          let filterApartmentLists = [];
          // Loop get the data fetching here
          querySnapshot.forEach((element) => {
            var data = element.data();

            data.docId = element.id;
            filterApartmentLists.push(data);
          });
          setInfo(filterApartmentLists);
        });
    } else if (city === "") {
      db.collection("Project_Details")
        .where("builder_details.builder_name", "==", name)
        .where("builder_area", "==", area)
        .where("house_type", "==", pathname_caps_category)
        .get()
        .then((querySnapshot) => {
          let filterApartmentLists = [];
          // Loop get the data fetching here
          querySnapshot.forEach((element) => {
            var data = element.data();

            data.docId = element.id;
            filterApartmentLists.push(data);
          });
          setInfo(filterApartmentLists);
        });
    } else if (area === "") {
      db.collection("Project_Details")
        .where("city", "==", city)
        .where("builder_details.builder_name", "==", name)
        .where("house_type", "==", pathname_caps_category)
        // .where("builder_area","==",age2)
        .get()
        .then((querySnapshot) => {
          let filterApartmentLists = [];
          // Loop get the data fetching here
          querySnapshot.forEach((element) => {
            var data = element.data();

            data.docId = element.id;
            filterApartmentLists.push(data);
          });
          setInfo(filterApartmentLists);
        });
    } else if (name === "") {
      db.collection("Project_Details")
        .where("city", "==", city)
        .where("builder_area", "==", area)
        .where("house_type", "==", pathname_caps_category)
        // .where("builder_area","==",age2)
        .get()
        .then((querySnapshot) => {
          let filterApartmentLists = [];
          // Loop get the data fetching here
          querySnapshot.forEach((element) => {
            var data = element.data();

            data.docId = element.id;
            filterApartmentLists.push(data);
          });
          setInfo(filterApartmentLists);
        });
    } else {
      db.collection("Project_Details")
        .where("city", "==", city)
        .where("builder_details.builder_name", "==", name)
        .where("builder_area", "==", area)
        .where("house_type", "==", pathname_caps_category)
        .get()
        .then((querySnapshot) => {
          let filterApartmentLists = [];
          querySnapshot.forEach((element) => {
            var data = element.data();

            data.docId = element.id;
            filterApartmentLists.push(data);
          });
          setInfo(filterApartmentLists);
        });
    }
  }
  // filter function end
  return (
    <div>
      <div
        className="background-img"
        style={{ height: "170px", width: "100%" }}
      >
        <div
          className="d-flex flex-column w-100 align-items-center justify-content-center"
          style={{ height: "170px", width: "100%" }}
        >
          <h1 className="text-white text-center">
            {pathname_caps_category} in {pathname_caps}
          </h1>
        </div>
      </div>

      <div className="container-fluid pt-5 pb-70">
        <div className="container">
          <div className="row filter-ui">
            {/* <div className="col-md-3">
              <p className="mb-2">Select Location</p>
              <select
                className="opt-true"
                value={pathname_caps}
                onChange={handleChangeCity}
              >
                <option value="">Select Location</option>
                {uniqueCityName.map((assign_city) => (
                  <option>{assign_city}</option>
                ))}
              </select>
            </div> */}
            <div className="col-md-4">
              <p className="mb-2">Select builder</p>
              <select onChange={handleChangeName}>
                <option value="">Select name</option>

                {uniquebuildername.map((builder_name) => (
                  <option>{builder_name}</option>
                ))}
              </select>
            </div>
            <div className="col-md-4">
              <p className="mb-2">Select Zone</p>
              <select onChange={handleChangeArea}>
                <option value="">Select Zone</option>
                {uniquebuilderArea.map((area_data) => (
                  <option>{area_data}</option>
                ))}
              </select>
            </div>
            <div className="col-md-4">
              <button
                onClick={() => filter_search()}
                type="Search"
                className="search-filter-btn"
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
      {info.length !== 0 ? (
        <div className="container-fluid bg-grey">
          <div className="container pt-40 pb-60 search-card-list">
            <div className="row">
              {/* Pass the info state to frame function */}
              {sliceArray.map((data, querySnapshot) => (
                <Datapage
                  builder_project_img={data.project_img}
                  builder_status={data.project_status}
                  builder_address={data.Address}
                  builder_id={data.docId}
                  project_name={data.project_name}
                  project_city={data.city}
                  project_name_keyword={data.project_name_keyword}
                />
              ))}
            </div>
            <div className="row pt-40">
              <div className="col-md-12">
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={info.length}
                  pageSize={PageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {(info.length === 0) & (loadData === true) ? (
        <div className="container-fluid bg-grey p-0">
          <div className="container pt-40 pb-60 search-card-list">
            <div className="row">
              <div className="col-md-12 text-center p-5">
                <img src={loadIcon} width="50px" height="50px" />
              </div>
            </div>
          </div>
          <CityPageFreq />
          <Footer />
        </div>
      ) : null}

      {(info.length === 0) & (noData === true) ? (
        <div className="container-fluid bg-grey p-0">
          <div className="container pt-40 pb-60 search-card-list">
            <div className="row">
              <div className="col-md-12 text-center">
                <img src={norecord} className="norecord" />
              </div>
            </div>
          </div>
          <CityPageFreq />
          <Footer />
        </div>
      ) : null}

      <div>{info.length > 0 ? <CityPageFreq /> : null}</div>
      <div>{info.length > 0 ? <Footer /> : null}</div>
    </div>
  );
}
// Main function end
// Frame function recall
const Datapage = ({
  builder_project_img,
  builder_status,
  builder_address,
  builder_id,
  project_name,
  project_name_keyword,
  project_city,
}) => {
  const navigate = useNavigate();
  // groupby function create

  const goToPosts = (value, p_name) => {
    navigate(
      `/projects/${p_name}`,

      { state: { id: value.docId } }
    );
    // window.location.reload();
  };

  // Fetching images set an alt image name 
  var httpsReference_project_logo = storage.refFromURL(builder_project_img);
  let alt_image_name = httpsReference_project_logo.name;
// Fetching images set an alt image name end
  return (
    <div className="col-md-4 pt-40 nthclr">
      <Box sx={{ minWidth: 175, backgroundColor: "greenyellow" }}>
        <Card>
          <React.Fragment>
            <CardContent
              onClick={() => goToPosts(builder_id, project_name_keyword)}
              style={{ padding: "0", border: "none", width: "100%" }}
              className="pointer"
            >
              <div className="relative">
                <div className="image-hover">
                  <CardMedia
                    component="img"
                    className="searchFilterCardListImg"
                    image={builder_project_img}
                    alt={alt_image_name}
                  ></CardMedia>
                </div>
                <div className="content-sep-top">
                  <h6>{project_name}</h6>
                  <div className="d-flex pt-1">
                    <ReactSVG src={locationmarker} />
                    <p style={{ marginBottom: "0", marginLeft: "5px" }}>
                      {builder_address}, {project_city}
                    </p>
                  </div>
                </div>
                <div className="projStatusbtn-absolute">
                  <Button className="projStatusbtn nthbg">
                    {builder_status}
                  </Button>
                </div>
              </div>
            </CardContent>
          </React.Fragment>
        </Card>
      </Box>
    </div>
  );
};

export default CategoryWithCityPage;
