import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import db from "./firebaseConfig";

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // seo content fetch end
  return (
    <div>
      <div
        className="background-img"
        style={{ height: "170px", width: "100%" }}
      >
        <div
          className="d-flex flex-column w-100 align-items-center justify-content-center"
          style={{ height: "170px", width: "100%" }}
        >
          <h1 className="text-white text-center">About us</h1>
        </div>
      </div>
      <div className="container pt-60 pb-60">
        <div className="row">
          <div className="col-md-12">
            <p>CGC Homes is co-founded and managed by,</p>
            <p>
              Ravishankar, Mechanical Engineer from College of Engineering,
              Guindy, Chennai with 13 years of sales and operations experience
              in the fields of Office Automation, IT, Engineering and Telecom.
              Has been associated with brands like Canon, Tata and Airtel.
            </p>
            <p>
              Joy, MS in IT and Management with 14 years of sales and operations
              experience in the fields of Healthcare and Telecom. Has been
              associated with brands like Tata, Dishnet DSL, Airtel and Sigma
              Laboratories.
            </p>
          </div>
        </div>
      </div>
      {<Footer />}
    </div>
  );
}
export default About;
