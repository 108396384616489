// all pages connected are Routing in this js file
// import section start
import ReactDOM from "react-dom";
import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./component/Layout";
import Home from "./component/home";
import ProjectDetail from "./component/projectDetail";
import SearchPage from "./component/searchPage";
import ContactPage from "./component/Contact";
import Disclaimer from "./component/Disclaimer";
import AboutUs from "./component/aboutUs";
import Plot from "./component/plot";
import PrivacyPolicy from "./component/PrivacyPolicy";
import AppartmentPage from "./component/appartmentPage";
import CityPage from "./component/cityPage";
import ProjectStatus from "./component/projectStatus";
import PropertyCategory from "./component/propertyCategory";
// import VillaPage from "./component/classComponent";
// import VillaAbout from "./component/vila";
import "@fontsource/rubik";
import firebaseApp from "firebase/app";

// import section end

// main function start
export default function App() {
  useEffect(() => {
    // listen();
    firebaseApp
      .auth()
      .signInAnonymously()
      .then(() => {})
      .catch((error) => {
        console.log(error);
        firebaseApp
          .auth()
          .signInAnonymously()
          .then(() => {})
          .catch((error) => {
            if (error.code === "auth/operation-not-allowed") {
            }
          });
        if (error.code === "auth/operation-not-allowed") {
        }
      });

    window.addEventListener("pageshow", (event) => {
      const historyTraversal =
        event.persisted ||
        (typeof window.performance != "undefined" &&
          window.performance.navigation.type === 2);

      if (historyTraversal) {
        window.location.reload();
      }
    });
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" index element={<Home />} />
          <Route path="search-result/:searchkey" element={<SearchPage />} />
          <Route path="projects/:name" element={<ProjectDetail />} />
          <Route path="category/:cityget" element={<Plot />} loading />
          <Route path="plot" element={<Plot />} loading />
          <Route path="/:cityget" element={<AppartmentPage />} loading />
          <Route path="apartment" element={<Plot />} loading />
          <Route path="villa" element={<Plot />} loading />
          <Route path="city/:city" element={<CityPage />} loading />
          <Route path="status/:status" element={<ProjectStatus />} loading />
          <Route path="contact" element={<ContactPage />} loading />
          <Route path="privacy-policy" element={<PrivacyPolicy />} loading />
          <Route path="about-us" element={<AboutUs />} loading />
          <Route
            path="property-category/:category"
            element={<PropertyCategory />}
            loading
          />
          <Route path="disclaimer" element={<Disclaimer />} loading />
          {/* <Route path="housetype/:citypath" element={<VillaPage />} loading />
          <Route path="socialshare" element={<VillaAbout/>} loading /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

// main function end
ReactDOM.render(<App />, document.getElementById("root"));
